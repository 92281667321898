<script setup lang="ts">
import { UtilityProps } from "./Utility.props"
import { sm, md, lg } from "~/utils/breakpoints"
import { Pagination } from "swiper/modules"

const props = defineProps<UtilityProps>()

const bgImage = computed(() => {
  if (!!props.bgImageSmall && sm.value) return props.bgImageSmall
  else if (!!props.bgImageMedium && md.value && !lg.value)
    return props.bgImageMedium

  return props.bgImageLarge
})
</script>

<template>
  <div
    class="
      rails-utility
      padded
      carousel-container-overflowed
      relative
      py-4
      md:py-16
    "
  >
    <UtilsWrapperImage
      v-if="bgImage"
      v-bind="bgImage"
      :key="bgImage.image.url"
      sizes="200px md:250px lg:350px xl:400px"
      class="
        wrapper-image-full-container-centered
        absolute
        inset-0
        -z-10
        overflow-hidden
      "
    />
    <div class="md:grid-container md:gap-y-6">
      <div class="mb-4 text-white md:col-span-4">
        <p v-if="caption" class="beaver md:pig mb-2 md:mb-4">
          {{ caption }}
        </p>
        <h3 v-if="title" class="dolphin-bold md:mammoth-bold">{{ title }}</h3>
      </div>

      <ClientOnly v-if="sm">
        <AppCarousel
          class="carousel-overflowed select-none"
          :swiper-props="{
            slidesPerView: 'auto',
            spaceBetween: 16,
            grabcursor: true,
            pagination: {
              enabled: true
            },
            modules: [Pagination],
            theme: 'pagination-tiffany-tiffany30'
          }"
          theme="pagination-tiffany-tiffany30"
          :items="cards"
        >
          <template #default="{ item }">
            <CardBenefits v-bind="item" />
          </template>
        </AppCarousel>
      </ClientOnly>
      <CardBenefits
        v-else
        v-for="card in cards"
        v-bind="card"
        class="col-span-4"
      />
    </div>
  </div>
</template>
